import { createApp } from 'vue'
import App from './App.vue'
import './assets/main.css'
import { createRouter, createWebHistory } from 'vue-router'



const router = createRouter({
    history: createWebHistory('/'),
    routes: [
      {
        path: '/',
        component: () => import('./pages/ChPage.vue')
      },
      {
        path: '/eng',
        component: () => import('./pages/EngPage.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/'
      }
    ]
  })
  
  const app = createApp(App)
  app.use(router)
  app.mount('#app')

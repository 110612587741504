<template>
  <header class="border-b border-gray-200 bg-white">
    <div class="max-w-[1400px] mx-auto px-4 flex items-center justify-between h-16">
      <div class="flex items-center space-x-8">
        <router-link to="/" class="flex items-center space-x-2">
          <Brain class="h-7 w-7 text-purple-600" />
          <span class="font-bold text-2xl">HKDSEAI</span>
        </router-link>
        
        <!-- Desktop Navigation -->
        <nav class="hidden md:flex items-center space-x-6">
          <router-link 
            v-for="item in navItems" 
            :key="item.path"
            :to="item.path"
            class="px-4 py-2 text-lg font-semibold text-white bg-gradient-to-r from-purple-500 to-indigo-600 rounded-full hover:from-purple-600 hover:to-indigo-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
          >
            {{ item.name }}
          </router-link>
        </nav>

        <!-- Mobile Navigation -->
        <button @click="isMenuOpen = !isMenuOpen" class="md:hidden">
          <Menu class="h-6 w-6" />
        </button>
      </div>
    </div>

    <!-- Mobile Menu -->
    <div v-if="isMenuOpen" class="md:hidden">
      <nav class="px-4 py-2 bg-white">
        <router-link 
          v-for="item in navItems" 
          :key="item.path"
          :to="item.path"
          class="block py-2 px-4 text-lg font-semibold text-purple-600 hover:bg-purple-50 rounded-lg"
        >
          {{ item.name }}
        </router-link>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import { Brain, Menu } from 'lucide-vue-next'

const isMenuOpen = ref(false)

const navItems = [
  { name: '中國語文', path: '/' },
  { name: '英國語文', path: '/eng' },
]
</script>